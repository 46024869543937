import React from "react";
import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";

import Advanced from "./examples/Advanced";
import Future from "./examples/Future";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Advanced />} />
        <Route path="/future" element={<Future />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
