import React, { useState, useMemo, useRef } from "react";
import ReactCardFlip from "react-card-flip";
import TinderCard from "react-tinder-card";
import gif from "../assets/confetti.gif";

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

const cardsArr = shuffleArray(arrayRange(2, 22, 1));
// making sure that the same card is at the start and end
cardsArr.push(1);
cardsArr.unshift(22);

function Future() {
  let flipBoolArr = [];
  for (let i = 0; i < cardsArr.length; i++) {
    flipBoolArr.push(true);
  }
  const [currentIndex, setCurrentIndex] = useState(cardsArr.length - 1);
  const [isFlipped, setFlip] = useState(flipBoolArr);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(cardsArr.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const handleClick = (event) => {
    if (event.detail === 2) {
      handleFlip(currentIndex);
    }
  };

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const handleFlip = (index) => {
    let tempArr = [...isFlipped];
    tempArr[index] = !tempArr[index];
    setFlip(tempArr);
  };

  const canGoBack = currentIndex < cardsArr.length - 1;

  const canSwipe = currentIndex >= 0;

  // set last direction and decrease current index
  const swiped = (direction, index) => {
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir, index) => {
    if (canSwipe && currentIndex < cardsArr.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  const flipAll = () => {
    let tempArr = [...isFlipped];
    for (let i = 0; i < tempArr.length; i++) {
      tempArr[i] = !tempArr[i];
    }
    setFlip(tempArr);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <link
        href="https://fonts.googleapis.com/css?family=Damion&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Alatsi&display=swap"
        rel="stylesheet"
      />
      <div className="hamburger-menu" onClick={toggleMenu}>
        <div className={`hamburger-icon ${isMenuOpen ? "open" : ""}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`mobile-menu ${isMenuOpen ? "menu-open" : ""}`}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://weand.me/tools"
        >
          Free Printable Cards
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://weand.me/store/"
        >
          Buy the Full Deck
        </a>
        <div className="menu-section">
          <h3 className="menu-header">Switch Decks</h3>
          <a href="/#/" className="menu-option">
            Original
          </a>
          <a href="/#/future" className="menu-option">
            Future Focused
          </a>
        </div>
      </div>
      <div className="cta-container cta-top">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://weand.me/tools"
        >
          Free Printable Cards
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://weand.me/store/"
        >
          Buy the Full Deck
        </a>
        <div className="dropdown-container">
          <button className="dropdown-button" onClick={toggleDropdown}>
            Switch Decks
            <span className={`dropdown-arrow ${isDropdownOpen ? "open" : ""}`}>
              👇
            </span>
          </button>
          {isDropdownOpen && (
            <div className="dropdown-content">
              <a href="/#/">Original</a>
              <a href="/#/future">Future Focused</a>
            </div>
          )}
        </div>
      </div>
      <div className="mainContainer">
        <div
          className={
            currentIndex === -1
              ? "cardContainer hideCardContainer"
              : "cardContainer"
          }
        >
          {cardsArr.map((val, index) => (
            <TinderCard
              ref={childRefs[index]}
              className="swipe"
              style={{ display: "none" }}
              onSwipe={(dir) => swiped(dir, index)}
              onCardLeftScreen={() => outOfFrame(val, index)}
            >
              <ReactCardFlip
                key={index}
                isFlipped={isFlipped[index]}
                flipDirection="vertical"
                className="pressable"
              >
                <div onClick={handleClick}>
                  <div
                    className={
                      currentIndex === index
                        ? "hex-container currentlySelectedCard"
                        : "hex-container"
                    }
                  >
                    <div className="hex">
                      <div className="hex-background">
                        <img
                          src={`./img/futureback-${val}.png`}
                          alt={`Card back ${val}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={handleClick}>
                  <div
                    className={
                      currentIndex === index
                        ? "hex-container currentlySelectedCard"
                        : "hex-container"
                    }
                  >
                    <div className="hex">
                      <div className="hex-background">
                        <img
                          src={`./img/futurefront-${val}.png`}
                          alt={`Card front ${val}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ReactCardFlip>
            </TinderCard>
          ))}
        </div>
        {currentIndex === -1 ? (
          <div className="cardContainer">
            <h2 className="finished-text">
              You can also get&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://weand.me/tools"
              >
                free printable cards
              </a>
              &nbsp;or&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://weand.me/store/"
              >
                buy the full deck
              </a>
              .
            </h2>
            <img src={gif} alt="Celebration GIF." className="gif" />
          </div>
        ) : (
          <></>
        )}
        <div className="buttons">
          <button
            style={{ backgroundColor: !canGoBack ? "#c3c4d3" : "#58585B" }}
            onClick={() => goBack()}
          >
            Back
          </button>
          <button
            style={{ backgroundColor: "#1071B9" }}
            onClick={() => flipAll()}
          >
            Flip
          </button>
          <button
            style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
            onClick={() => swipe("right")}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Future;
